import React from 'react';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

const gallery1 = 'img/sections/hero/gallery-1.webp';
const gallery2 = 'img/sections/hero/gallery-2.webp';
const gallery3 = 'img/sections/hero/gallery-3.webp';
const gallery4 = 'img/sections/hero/gallery-4.webp';
const gallery5 = 'img/sections/hero/gallery-5.webp';
const gallery6 = 'img/sections/hero/gallery-6.webp';

const BackgroundSlider = () => {
	const [photo, setPhoto] = useState(1);

	useEffect(() => {
		const interval = setInterval(() => {
			change();
		}, 3000);

		return () => {
			clearInterval(interval);
		};
	}, [photo]);

	const change = () => {
		if (photo === 5) {
			setPhoto(1);
			return;
		}

		setPhoto((prev) => prev + 1);
	};

	const imageUrl = () => {
		switch (photo) {
			case 1:
				return gallery1;
			case 2:
				return gallery2;
			case 3:
				return gallery3;
			case 4:
				return gallery4;
			case 5:
				return gallery5;
			case 6:
				return gallery6;
			default:
				return null;
		}
	};

	return (
		<Box
			style={{
				backgroundImage: `url(${imageUrl()})`,
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				width: '100%',
				height: '100%',
				marginTop: '0',
				transition: 'all 1s ease-in-out .5s',
			}}></Box>
	);
};

export default BackgroundSlider;
