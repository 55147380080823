import React from 'react';

const Preloader = () => {
  return (
    <div id='preloader'>
      <div className='alignment'>
        <div className='v-align center-middle'>
          <div id='heart-on-center' className='spinner-animation'>
            <i className='de-icon-heart'></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
