import { useState } from 'react';
import parser from 'html-react-parser';
import { Box, Button, Stack, Typography, Grid } from '@mui/material';
import { PopupModal } from 'react-calendly';
import { IoCall } from 'react-icons/io5';
import { MdOutlineAlternateEmail } from 'react-icons/md';
import { GrSchedule } from 'react-icons/gr';
import content from '../siteData/contactUs.json';
import CCForm from '../components/CCForm';

const ContactUs = () => {
	const [open, setOpen] = useState(false);

	return (
		<Stack
			component='section'
			className='contact-us bg-light-gray no-top-margin top-padding'
			id='contact-us'
			sx={{ marginTop: '3rem' }}>
			<Typography variant='h2'>{content.title}</Typography>

			<Box>
				<Box className='margin-bottom'>
					<Typography variant='p'>{parser(content.intro)}</Typography>
					<Grid container alignItems='center' justifyContent='center' spacing={2}>
						<Grid item>
							<Button
								startIcon={<IoCall />}
								variant='contained'
								sx={{ marginTop: '1rem' }}
								onClick={(e) => {
									window.location.href = 'tel:503-770-0382';
									e.preventDefault();
								}}>
								Call Us
							</Button>
						</Grid>
						<Grid item>
							<Button
								startIcon={<MdOutlineAlternateEmail />}
								variant='contained'
								sx={{ marginTop: '1rem' }}
								onClick={(e) => {
									window.location.href = 'mailto:info@noteworthydjs.com';
									e.preventDefault();
								}}>
								Email Us
							</Button>
						</Grid>
						<Grid item>
							<Button
								startIcon={<GrSchedule />}
								variant='contained'
								sx={{ marginTop: '1rem' }}
								onClick={() => {
									setOpen(true);
								}}>
								<PopupModal
									url='https://calendly.com/kimberly-noteworthydjs/30min'
									rootElement={document.getElementById('root')}
									open={open}
									onModalClose={() => {
										setOpen(false);
									}}
								/>
								Schedule a Call
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<CCForm />
			<Box className='address'>
				<Stack>
					<Typography variant='subtitle1'>Noteworthy Productions, LLC</Typography>
					<Typography variant='subtitle2'>6635 N. Baltimore Ave., Suite 8A</Typography>
					<Typography variant='subtitle2'>Portland, OR 97203</Typography>
				</Stack>
			</Box>
		</Stack>
	);
};

export default ContactUs;
