import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: { main: '#e86c6c', contrastText: '#ebe8e8' },
    secondary: {
      main: '#6c6ce8',
      contrastText: '#ebe8e8',
    },
    text: {
      primary: '#4a4a4a',
      secondary: '#e9e6e6',
    },
  },
  typography: {
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    h1: {
      fontFamily: `"Shadows Into Light", "cursive"`,
    },
  },
  components: {
    overrides: {
      MuiButton: {
        fontWeight: '500',
        lineHeight: 1.75,
        letterSpacing: '0.15rem',
        padding: '.75rem',
        textTransform: 'uppercase',
        maxWidth: '20rem',
      },
      paper: {
        maxWidth: '80%',
      },
    },
  },
});

export default theme;
