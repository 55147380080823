import React from 'react';

const CCForm = () => {
	return (
		<div
			className='checkcherry__widget__contact-form'
			data-props='{"apiKey":"9CN-KW79-NM7","contactFormId":2994,"iframe":false,"host":"https://noteworthy-djs.checkcherry.com"}'></div>
	);
};

export default CCForm;
