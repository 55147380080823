import React from 'react';
import { Stack } from '@mui/material';

import ContactUs from '../sections/ContactUs';
import Hero from '../sections/Hero';

import '../style/css/style.css';

const Homepage = () => {
	return (
		<Stack sx={{ position: 'relative', padding: '0' }}>
			<Hero />
			<ContactUs />
		</Stack>
	);
};

export default Homepage;
