import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Grid, Link } from '@mui/material';

const Socials = () => {
	return (
		<Grid
			container
			spacing={4}
			className='socials'
			sx={{ position: 'absolute', top: '90%', color: '#f88' }}>
			<Grid item>
				<Link href='https://www.facebook.com/NoteworthyDJs/'>
					<FacebookIcon />
				</Link>
			</Grid>
			<Grid item>
				<Link href='https://www.instagram.com/noteworthydjs/'>
					<InstagramIcon />
				</Link>
			</Grid>
			<Grid item>
				<Link href='https://twitter.com/noteworthydjs'>
					<TwitterIcon />
				</Link>
			</Grid>
			<Grid item>
				<Link href='https://www.youtube.com/c/Noteworthydjs/'>
					<YouTubeIcon />
				</Link>
			</Grid>
		</Grid>
	);
};

export default Socials;
