import React from 'react';
import logo from '../images/NWPB-no-text-300x300.webp';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
// import BackgroundSlider from 'react-background-slider';
import BackgroundSlider from '../components/Slider/BackgroundSlider';

import Socials from '../components/Socials';
import { HashLink } from 'react-router-hash-link';

const Hero = () => {
	return (
		<Stack
			component='section'
			id='hero'
			spacing={2}
			sx={{
				width: '100vw',
				height: '100vh',
				position: 'relative',
				alignItems: 'center',
				overflow: 'hidden',
				marginTop: '0',
			}}>
			<Paper
				elevation={6}
				sx={{
					backgroundColor: '#ffffff8a',
					maxWidth: '80%',
					position: 'absolute',
					top: '15vh',
					padding: '2rem',
				}}>
				<Stack
					spacing={2}
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<Box className='logo' component='img' src={logo} alt='noteworthy photo booths logo' />
					<Typography className='nwpb-title-card' align='center' variant='h3'>
						Noteworthy Photo Booths
					</Typography>
					<Typography className='coming-soon' align='center' variant='h1' color='primary'>
						coming soon...
					</Typography>
					<Typography className='slogan' align='center' variant='h6'>
						Bring the fun and make it an epic night.
					</Typography>
					<Typography className='subheader1' align='center' variant='subtitle1'>
						Locally Owned :: Hosted Booths :: Props & Prints Included
					</Typography>

					<Button variant='contained'>
						<HashLink smooth to='#contact-us'>
							Contact Us
						</HashLink>
					</Button>
				</Stack>
			</Paper>

			<BackgroundSlider />
			<Socials />
		</Stack>
	);
};

export default Hero;
