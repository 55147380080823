import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import Homepage from './pages/Homepage';
import './style/css/style.css';
import { ThemeProvider } from '@mui/material';
import Preloader from './components/Preloader';

import theme from './style/theme';

function App() {
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		setTimeout(() => setIsLoading(false), 1000);
	}, []);

	return (
		<div className='App'>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<BrowserRouter>
					<Routes>
						<Route index element={<Homepage />} />
						<Route path='/index' element={<Homepage />} />
						<Route path='/home' element={<Homepage />} />
					</Routes>
				</BrowserRouter>
			</ThemeProvider>
			{isLoading ? <Preloader /> : ''}
		</div>
	);
}

export default App;
